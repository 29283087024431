import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styleNotFound from '../../css/NotFound/notFound.module.css'

export default function NotFoundPage () {
  const navigate = useNavigate()
  const [fadeOut, setFadeOut] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setFadeOut(true), 2500)
    const redirectTimer = setTimeout(() => navigate('/mdm/home'), 3000)

    return () => {
      clearTimeout(timer)
      clearTimeout(redirectTimer)
    }
  }, [navigate])

  return (
    <div
      className={
        fadeOut ? styleNotFound.transitionNotFound : styleNotFound.containerNotFound
      }
    >
      <h1 className={styleNotFound.titleNotFound}>404 - Página No Encontrada</h1>
      <p className={styleNotFound.textNotFound}>Redirigiendo a la página principal...</p>
    </div>
  )
}
