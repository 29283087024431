import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import '../../css/generals/paper.css'
import styles from '../../css/modals/Modal.module.css'
import Swal from 'sweetalert2'
import { postData } from '../../actions/servicesHttp'
import { popupSimple } from '../generals/popups'
import PropTypes from 'prop-types'

export default function ModalInstallApplications ({ show, onHide, title, btnError, btnSuccess }) {
  const { id } = useParams()
  const [files, setFiles] = useState(null)

  function inputFileHandling () {
    const input = document.querySelector('#fileToInstallApplicationsGroup')

    input.addEventListener('change', e => {
      const span = document.querySelector('#file_name_install_applications_group')

      if (input?.files?.length === 0) {
        span.innerHTML = 'Ningún archivo selccionado'
      } else if (input?.files?.length > 0) {
        span.innerHTML = input.files[0].name
        setFiles(input.files)
      }
    })
  }

  const uploadContent = async () => {
    const f = new FormData()

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        f.append('files', files[i])
      }

      let timerInterval

      Swal.fire({
        title: '¡Enviando solicitud!',
        html: '',
        timer: 30000,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(500)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

      const response = await postData('upload/install', f)

      if (response.data.length !== false) {
        let url = response.data.url
        url = url.trim()
        const splitUrl = url.split('/')
        installApps(url, splitUrl)
        setFiles(null)
        onHide()
        setFiles(null)
      } else {
        popupSimple('error', 'El proceso no pudo ser completado!')
      }
    } else {
      popupSimple('info', 'No se seleccionó ningún archivo!')
    }
  }

  const installApps = async (url, fileName) => {
    const json = {
      action: 'install',
      data: {
        fileName: fileName[fileName.length - 2] + '.' + fileName[fileName.length - 1],
        url,
        type: 'app'
      },
      date: new Date(Date.now()),
      devicesId: [],
      groupsId: [parseInt(id)]
    }

    const { data } = await postData('actions', [json])

    if ((data[0].result === true)) {
      popupSimple('success', 'Solicitud enviada con éxito!')
    } else {
      popupSimple('error', 'Error. No se pudo realizar la acción!')
    }
  }

  return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                key={'installApplications'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.modalBackgroundChangeBody}>
                        <label>Seleccionar la aplicación a instalar:</label>
                        <input type="file" accept=".exe, .apk" id="fileToInstallApplicationsGroup" name="files" className={styles.fancy_file}/>
                        <label htmlFor="fileToInstallApplicationsGroup" className={styles.fancy_file_label}>
                            <span className={styles.fancy_file_button} onClick={() => inputFileHandling()}>
                                <i className="pi pi-download"></i></span>
                            <span className={styles.fancy_file_name}>
                                <span id="file_name_install_applications_group">Ningún archivo seleccionado</span>
                            </span>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                      onHide()
                      setFiles(null)
                    }} variant="danger">
                        {btnError}
                    </Button>
                    <Button onClick={() => uploadContent()} variant="primary" id="btnModalSucess" >
                        {btnSuccess}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  )
}

ModalInstallApplications.propTypes = {
  onHide: PropTypes.func,
  title: PropTypes.string,
  show: PropTypes.bool,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string
}
