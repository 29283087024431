import React, { useEffect, useState, useRef } from 'react'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import ModalBody from 'react-bootstrap/esm/ModalBody'
import ModalFooter from 'react-bootstrap/esm/ModalFooter'
import ModalTitle from 'react-bootstrap/esm/ModalTitle'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import styles from '../../css/modals/Modal.module.css'
import styleschecks from '../../css/generals/Checkbox.module.css'
import stylesCommand from '../../css/modals/ModalCommand.module.css'
import Swal from 'sweetalert2'
import store from '../../reducer/store'
import { getData, postData } from '../../actions/servicesHttp'
import { Accordion, AccordionTab } from 'primereact/accordion'

export default function ModalCommands ({ devices, onHide, type, component, onSelecteds, group, realTime, socketSendActions, socketReceiveActions }) {
  const { user } = store.getState()
  const [commandInput, setCommandInput] = useState('')
  const [commandHistory, setCommandHistory] = useState([])
  const [rawCommand, setRawCommand] = useState('')
  const [rawResponse, setRawResponse] = useState('')
  const [currentPath, setCurrentPath] = useState('')
  const [destiny, setDestiny] = useState('Todos')
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toLocaleString('es-AR', { hour12: false }))
  const [commandsInProgress, setCommandsInProgress] = useState([])
  const [currentCommandIndex, setCurrentCommandIndex] = useState(null)
  const [expandedHistory, setExpandedHistory] = useState(false)
  const commandDate = new Date().toLocaleString('es-AR', { hour12: false })
  const intervalRefs = useRef({})
  const latestCommandRef = useRef(null)

  useEffect(() => {
    console.log({ realTime })
    let timer
    if (realTime) {
    //! tenes un intervalo aca que es un longpoooling

      timer = setInterval(() => {
        setCurrentDateTime(new Date().toLocaleString('es-AR', { hour12: false }))
      }, 1000)
    } else {
      setCurrentDateTime('')
    }
    return () => clearInterval(timer)
  }, [realTime])

  const handleReceiveAction = (response) => {
    if (response?.result && response !== latestCommandRef.current) {
      latestCommandRef.current = response

      const responseTime = new Date().toLocaleString('es-AR', { hour12: false })
      setRawResponse(response?.result)
      setCurrentPath(response?.path)

      setCommandHistory((prevHistory) => {
        const newHistory = [...prevHistory]
        if (currentCommandIndex !== null) {
          newHistory.splice(currentCommandIndex + 1, 0, `${responseTime} - Respuesta: ${response?.result}`)
        } else {
          newHistory.push(`${responseTime} - Respuesta: ${response?.result}`)
        }
        return newHistory
      })
      setCurrentCommandIndex(null)
    }
  }

  useEffect(() => {
    if (realTime && socketReceiveActions) {
      socketReceiveActions(handleReceiveAction)

      return () => {
        socketReceiveActions(null)
      }
    }
  }, [realTime, socketReceiveActions, currentCommandIndex])

  useEffect(() => {
    if (realTime) {
      Object.values(intervalRefs?.current).forEach(clearInterval)
      intervalRefs.current = {}
      setCommandsInProgress([])
      return
    }
    //! tenes un intervalo aca que es un longpoooling
    commandsInProgress.forEach(commandId => {
      if (!intervalRefs?.current[commandId]) {
        intervalRefs.current[commandId] = setInterval(async () => {
          const hasResponse = await pollCommandResponse(commandId)
          if (hasResponse) {
            clearInterval(intervalRefs?.current[commandId])
            delete intervalRefs?.current[commandId]
            setCommandsInProgress(prevCommands => prevCommands?.filter(id => id !== commandId))
          }
        }, 120000)
      }
    })

    return () => {
      Object.values(intervalRefs?.current).forEach(clearInterval)
    }
  }, [commandsInProgress, realTime])

  useEffect(() => {
    if (Array.isArray(devices) || devices?.length === 0) {
      setDestiny('Todos')
    } else {
      setDestiny('Seleccionados')
    }
  }, [devices])

  useEffect(() => {
    if (devices && devices.length > 0) { latestCommands(devices[0]) }
  }, [])

  const handleCommandChange = (e) => setCommandInput(e?.target?.value)

  const clearHistory = () => setCommandHistory([])

  const handleKeyDown = ({ key }) => { if (key === 'Enter') executeCommand() }

  const toggleExpand = () => setExpandedHistory(!expandedHistory)

  const latestCommands = async (deviceId) => {
    const { data } = await getData(`commands/latest/${deviceId}`)
    if (data?.length > 0) {
      const latestCommands = data
        ?.filter(command => command.response)
        ?.map(command => {
          const commandTime = new Date(command?.dateCommand).toLocaleString('es-AR', { hour12: false })
          const responseTime = command.dateResponse ? new Date(command.dateResponse).toLocaleString('es-AR', { hour12: false }) : null
          const responseText = command?.response

          return `${commandTime} - Comando: ${command?.command} \n${responseTime ? responseTime + ' - ' : ''}Respuesta: ${responseText}`
        })
        ?.reverse()

      setCommandHistory(latestCommands)
    }
  }

  const saveCommandToDatabase = async (command) => {
    const commandData = {
      command,
      dateCommand: new Date().toISOString(),
      deviceId: devices[0],
      userId: +user.userId
    }
    const response = await postData('commands', commandData)
    return response?.data?.commands?.id
  }

  const pollCommandResponse = async (commandId) => {
    const { data } = await getData(`command/${commandId}`)
    if (data?.response && data.response !== 'aun no disponible') {
      const responseTime = data?.dateResponse
        ? new Date(data.dateResponse).toLocaleString('es-AR', { hour12: false })
        : new Date().toLocaleString('es-AR', { hour12: false })

      setCommandHistory((prevHistory) => {
        const newHistory = [...prevHistory]
        if (currentCommandIndex !== null) {
          newHistory.splice(currentCommandIndex + 1, 0, `${responseTime} - Respuesta: ${data?.response}`)
        } else {
          newHistory.push(`${responseTime} - Respuesta: ${data?.response}`)
        }
        return newHistory
      })
      setCurrentCommandIndex(null)
      setRawResponse(data?.response)
      clearInterval(intervalRefs.current[commandId])
      return true
    }

    return false
  }

  const executeCommand = async () => {
    if (commandInput.trim() === '') return
    setRawResponse('')

    const json = {
      action: 'command',
      data: { command: commandInput },
      devicesId: type === 'dashboardGroup' ? [] : devices !== undefined ? devices : [],
      groupsId: component === 'sendCommand' ? [] : group !== undefined ? group : destiny === 'Todos' ? [1] : []
    }

    setRawCommand(commandInput)
    setCommandHistory((prevHistory) => [
      ...prevHistory,
      `${commandDate} - Comando: ${commandInput}`
    ])
    setCurrentCommandIndex(commandHistory.length)

    const commandId = await saveCommandToDatabase(commandInput)
    setCommandsInProgress(prevCommands => [...prevCommands, commandId])

    if (realTime) {
      if (currentPath) json.path = currentPath
      socketSendActions([json])
    } else {
      await postData('actions', [json])
      await pollCommandResponse(commandId)
    }

    setCommandInput('')
  }

  const downloadCSV = () => {
    if (!commandHistory.length) {
      Swal.fire({
        icon: 'warning',
        title: 'Historial vacío',
        text: 'No hay historial para descargar'
      })
      return
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Descargar tabla en formato CSV?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, descargar',
      cancelButtonText: 'No, cerrar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const csvContent = `data:text/csv;charset=utf-8,${commandHistory.map(e => e).join('\n')}`
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'command_history.csv')
        document.body.appendChild(link)

        link.click()
        document.body.removeChild(link)
      }
    })
  }

  return (
    <>
      <ModalHeader className={stylesCommand.headerCommand}>
        <ModalTitle>Centro de control de comandos</ModalTitle>
        <div className={stylesCommand.infoTime}>
          {realTime &&
            <>
              <i className="pi pi-clock" />
              <p className={stylesCommand.connectCommand}>{currentDateTime}</p>
            </>
          }
          <p className={realTime ? stylesCommand.commandConnected : stylesCommand.commandDisconnected}>
            {realTime ? 'Conectado' : 'Desconectado'}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={stylesCommand.containerCommand}>
          <p>Campo de comando:</p>
          <div className={stylesCommand.containerInputCommand}>
            <input
              value={commandInput}
              onChange={handleCommandChange}
              onKeyDown={handleKeyDown}
              placeholder='Ingrese comando'
              className={stylesCommand.inputCommand}
            />
            <Button
              className={`btn btn-primary ${stylesCommand.btnCommand}`}
              onClick={executeCommand}
            >
              Ejecutar
            </Button>
          </div>
          {
            currentPath &&
            <div>
              Ruta actual: <label className={stylesCommand.currentPath}>{currentPath}</label>
            </div>
          }
          <textarea
            readOnly
            value={`>${rawCommand} \n${rawResponse && rawResponse}`}
            className={`${styles.inputModal} ${styles.inputUsers} ${styleschecks.focus} ${stylesCommand.commandResponse}`}
          />
        </div>

        <Accordion activeIndex={[0]} >
          <AccordionTab header="Historial" >
            <div className={stylesCommand.containerHistoryBtn}>
              <Button
                className={`btn btn-secondary ${stylesCommand.btnHistory}`}
                onClick={clearHistory}
              >
                Borrar historial
              </Button>
              <Button
                className={`btn btn-secondary ${stylesCommand.btnHistory}`}
                onClick={downloadCSV}
              >
                Descargar historial
              </Button>
            </div>
            <div className={`container ${stylesCommand.containerHistory}`}>
              <textarea
                readOnly
                value={commandHistory && commandHistory?.join('\n')}
                className={`${styles.inputModal} ${styles.inputUsers} ${styleschecks.focus} ${commandHistory?.length > 0
                  ? expandedHistory ? stylesCommand.commandHistoryExpanded : stylesCommand.commandHistory
                  : stylesCommand.commandHistoryEmpty
                  }`
                }
              />
              {
                commandHistory?.length > 0 &&
                <Button
                  variant="link"
                  onClick={toggleExpand}
                  className={`${stylesCommand.btnViewHistory}`}
                >
                  {expandedHistory && commandHistory.length > 0 ? 'Ver menos' : 'Ver más'}
                </Button>
              }
            </div>
          </AccordionTab >
        </Accordion >
      </ModalBody >
      <ModalFooter>
        <Button
          className='btn btn-danger'
          onClick={onHide}
        >Cerrar
        </Button>
      </ModalFooter>
    </>
  )
}

ModalCommands.propTypes = {
  devices: PropTypes.array,
  onHide: PropTypes.func,
  type: PropTypes.string,
  component: PropTypes.string,
  onSelecteds: PropTypes.func,
  group: PropTypes.array,
  realTime: PropTypes.bool.isRequired,
  socketSendActions: PropTypes.func,
  socketReceiveActions: PropTypes.func
}
