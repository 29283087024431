import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Applications from './views/Applications/applications.js'
import Configs from './views/Preferences/Configs/configsPreferences'
import Dashboard from './views/Devices/Dashboard/dashboard'
import DashboardGroup from './views/Groups/Dashboard/dashboardGroups'
import Devices from './views/Devices/devices'
import Gallery from './views/Devices/Gallery/gallery'
import Groups from './views/Groups/groups'
import Home from './views/Home/home'
import Index from './views/Index/index'
import Listas from './views/Safeweb/listas'
import Locked from './views/Preferences/Locked/lockedsPreferences'
import SafeWeb from './views/Safeweb/home'
import Server from './views/Preferences/Server/serverPreferences'
import LocationHistory from './views/Devices/LocationHistory/locationHistory'
import Users from './views/Users/users'
import Layout from './layout/Layout'
import Words from './views/Safeweb/words'
import PreferencesSafeweb from './views/Safeweb/preferences'
import PreferencesReports from './views/Preferences/Reports/reportsPreferences'
import LogOut from './components/generals/logout'
import Geofences from './views/Geofences/geofences'
import GeofencesPreferences from './views/Preferences/Geofences/geofencesPreferences'
import UsageTime from './views/Preferences/UseTime/usageTimePreferences'
import RSPreferences from './views/Preferences/RxartSecure/RSPreferences'
import NotFoundPage from './views/NotFound/notFoundPage.js'
import Roles from './views/Roles/Roles'
import 'primereact/resources/themes/lara-light-indigo/theme.css' // theme
import 'primereact/resources/primereact.min.css' // core css
import 'primeicons/primeicons.css'
import './css/globals.css'
import './css/styles.css'
// ? importacion de manager de conexion socketio

const App = () => {
  // console.log(ConnectionManager().isConnected)
  return (
    <div className="wrapper">
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/mdm" element={<LogOut component={Layout} />}>
          {/* Redirige al home cuando la ruta sea solo '/mdm' */}
          <Route index element={<Navigate to="home" replace />} />
          <Route index path="home" element={<LogOut component={Home} />} />
          <Route path="devices" element={<LogOut component={Devices} />} />
          <Route path="device/:id" element={<LogOut component={Dashboard} />} />
          <Route path="applications" element={<LogOut component={Applications} />} />
          <Route path="history/:id" element={<LogOut component={LocationHistory} />} />
          <Route path="gallery/:code" element={<LogOut component={Gallery} />} />
          <Route path="groups" element={<LogOut component={Groups} />} />
          <Route path="group/:id" element={<LogOut component={DashboardGroup} />} />
          <Route path="preferences/configs" element={<LogOut component={Configs} />} />
          <Route path="preferences/lost" element={<LogOut component={Locked} />} />
          <Route path="preferences/server" element={<LogOut component={Server} />} />
          <Route path="preferences/reports" element={<LogOut component={PreferencesReports} />} />
          <Route path="preferences/geofence-notifications" element={<LogOut component={GeofencesPreferences} />} />
          <Route path="preferences/usage-time" element={<LogOut component={UsageTime} />} />
          <Route path="preferences/rxart-secure" element={<LogOut component={RSPreferences} />} />
          <Route path="users" element={<LogOut component={Users} />} />
          <Route path="roles" element={<LogOut component={Roles} />} />
          <Route path="safeweb" element={<LogOut component={SafeWeb} />} />
          <Route path="safeweb-preferences" element={<LogOut component={PreferencesSafeweb} />} />
          <Route path="lists" element={<LogOut component={Listas} />} />
          <Route path="words" element={<LogOut component={Words} />} />
          <Route path="geofences" element={<LogOut component={Geofences} />} />
        </Route>
        <Route path='*' element={<NotFoundPage replace />} />
      </Routes>
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
    </div>
  )
}

export default App
