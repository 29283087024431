import React, { useEffect, useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import Swal from 'sweetalert2'

// eslint-disable-next-line react/prop-types
function LogOut ({ component: Component }, props) {
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  const checkSession = () => {
    const keys = ['userId', 'token', 'range', 'roles']
    const sessionValid = keys.every((key) => sessionStorage.getItem(key) !== null)

    if (sessionValid) {
      setIsAuthenticated(true)
      console.log({ isAuthenticated })
    } else {
      setIsAuthenticated(false)
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Debe iniciar sesión nuevamente!',
        showConfirmButton: false,
        timer: 1500
      }).then(() => navigate('/'))
    }
  }

  useEffect(() => checkSession(), [])

  if (isAuthenticated === null) return <></>

  if (!isAuthenticated) return <Navigate to="/" replace />

  return <Component {...props} />
}

export default LogOut
