export function jsonActionsSecurityGroups (handleModal, data, deleted, { actionLockDevices, actionUnlockDevices }) {
  // Despliegue de boton de acciones de contenido
  const result = []

  if (actionLockDevices) {
    result.push({
      label: 'Desbloquear Dispositivos',
      icon: 'pi pi-lock-open',
      command: () => {
        handleModal('desbloquear', true)
      }
    })
  }

  if (actionUnlockDevices) {
    result.push({
      label: 'Bloquear Dispositivos',
      icon: 'pi pi-lock',
      command: () => {
        handleModal('bloquear', true)
      }
    })
  }
  // {
  //     label: "Activar Dispositivos",
  //     icon: "pi pi-check-circle",
  //     command: () => {
  //         //deleted("activar");
  //     }
  // },
  // {
  //     label: "Desactivar Dispositivos",
  //     icon: "pi pi-times-circle",
  //     command: () => {
  //         //deleted("desactivar");
  //     }
  // }
  return result
}

export function jsonActionsSystemGroups (rebootShutdownRestart, id, { actionShutdown, actionReboot }) {
  // Despliegue de boton de acciones de sistema
  const result = []

  if (actionShutdown) {
    result.push({
      label: 'Apagar',
      icon: 'pi pi-power-off',
      command: () => {
        rebootShutdownRestart('shutdown', 'Apagar', 'groups', id)
      }
    })
  }
  if (actionReboot) {
    result.push({
      label: 'Reiniciar',
      icon: 'pi pi-replay',
      command: () => {
        rebootShutdownRestart('reboot', 'Reiniciar', 'groups', id)
      }
    })
  }

  return result
}

export function jsonApplicationsGroups (idGroup, { actionInstallApplication, actionUninstallApplication }, handleModal) {
  // Despliegue de boton de acciones de aplicaciones
  const result = []

  if (actionUninstallApplication) {
    result.push({
      label: 'Desinstalar aplicaciones',
      icon: 'pi pi-upload',
      command: () => {
        handleModal('uninstallApplications', true)
      }
    })
  }

  if (actionInstallApplication) {
    result.push({
      label: 'Instalar aplicaciones',
      icon: 'pi pi-download',
      command: () => {
        handleModal('installApplications', true)
      }
    })
  }

  return result
}
