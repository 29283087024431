import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getData, putData } from '../../../actions/servicesHttp'
import Loading from '../../../components/generals/loading'
import { Toast } from 'primereact/toast'
import store from '../../../reducer/store'
import LockedDevices from '../../../components/modals/lockeds'
import ButtonsAndTables from '../../../components/Dashboards/Groups/buttonsAndTable'
import ModalGeneric from '../../../components/modals/modal'
import ConfigReportGroups from '../../../components/modals/configReportsGroup'
import DataTableDemo from '../../../components/generals/datatables/datatable'
import SendMessages from '../../../components/modals/sendMessage'
import Header from '../../../components/generals/header'
import Swal from 'sweetalert2'
import $ from 'jquery'
import DataTableLazyDevices from '../../../components/generals/datatables/dataTableLazyDevices'
import SpeedDialMenu from '../../../components/Dashboards/speedDialMenu'
import ModalBackgroundChange from '../../../components/modals/modalBackgroundChange'
import ModalContentDownload from '../../../components/modals/modalContentDownload'
import stylesIndex from '../../../css/Index.module.css'
import ModalUseTimePreferences from '../../../components/modals/modalUseTimePreferences'
import ModalDeleteGroupPreference from '../../../components/modals/ModalDeleteGroupPreference'
import { formatDate } from '../../../components/generals/charts/utils/DatesFormats'
import ModalInstallApplications from '../../../components/modals/ModalInstallApplications'
import ModalUninstallApplications from '../../../components/modals/ModalUninstallApplications'

export default function DashboardGroup () {
  const { user } = store.getState()
  const role = user.roles
  const toast = useRef(null)
  const { id } = useParams()
  const [action, setAction] = useState(null)
  const [preferences, setPreferences] = useState(null)
  const [data, setData] = useState(null)
  const [idsDevices, setIdsDevices] = useState(null)
  const [notGroup, setNotGroup] = useState({
    users: undefined,
    devices: undefined,
    geofences: undefined

  })
  const [columnsRole] = useState([
    ...role?.devicesPage.columDataTableDevices
  ])

  const [selectedRows, setSelectedRows] = useState({
    users: [],
    addUsers: [],
    devices: [],
    addDevices: [],
    geofences: [],
    addGeofences: []
  })
  const [modalsShow, setModalsShow] = useState({
    name: false,
    message: false,
    locked: false,
    reports: false,
    useTimePreferences: false,
    deleteGroupPreference: false,
    addMembers: {
      addUsers: false,

      addDevices: false,
      addGeofences: false
    },
    installApplications: false,
    uninstallApplications: false
  })

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getDataGroup()
      await getPreferencesLocked(data)
      devicesSendMessage(selectedRows)
    }

    fetchData()
    membersNotGroup()
  }, [])

  const getDataGroup = async () => {
    const response = await getData('group', id)
    if (response?.data?.devices?.length > 0) {
      const devices = response.data.devices
      devices.forEach((info) => {
        const soSplit = info.so.split('_-_')
        const so = `${soSplit[0]} ${soSplit[1]}`
        info.so = so
        const modelSplit = info.model.split('_-_')
        const model = `${modelSplit[0]} ${modelSplit[1]}`
        info.model = model
        if (info.last_date === null) {
          info.last_date = '10-01-2023 10:23:00'
        } else {
          info.last_date = formatDate(info.last_date)
        }
      })
      response.data.devices = devices
    }
    setData(response.data)
    setIdsDevices(response.data.devices)

    return response
  }

  const getPreferencesLocked = async (x) => {
    const response = await getData('preferences', x?.preference?.id ?? 1)
    setPreferences(response.data)
  }

  function devicesSendMessage (selected) {
    const devices = selected.devices
    const ids = devices.map((device) => device.id)
    setIdsDevices(ids)
  }

  function filterNotGroup (members) {
    const array = []
    members.forEach((member) => {
      if (member.groups.length === 0) {
        array.push(member)
      } else {
        const search = member.groups.find((item) => item.id === parseInt(id))
        if (search === undefined) {
          array.push(member)
        }
      }
    })

    return array
  }

  const filtered = (type, filters) => {
    const info = filters
    let selecteds
    if (type === 'users') {
      selecteds = selectedRows.users
    } else if (type === 'devices') {
      selecteds = selectedRows.devices
    // } else {
    } else if (type === 'geofences') {
      selecteds = selectedRows.geofences
    }
    for (const selected of selecteds) {
      if (type === 'users') {
        info.users = info.users.filter((item) => item.id !== selected.id)
      } else if (type === 'devices') {
        info.devices = info.devices.filter((item) => item.id !== selected.id)
      } else {
        info.geofences = info.geofences.filter((item) => item.id !== selected.id)
      }
    }
    const array = info
    return array
  }

  async function membersNotGroup () {
    const users = await getData('users')

    const geofences = await getData('geofences')
    let notUsers = filterNotGroup(users?.data)
    const notGeofences = filterNotGroup(geofences?.data)
    notUsers = notUsers.filter((item) => item.id !== 1)
    setNotGroup({ ...notGroup, users: notUsers, devices: undefined, geofences: notGeofences })
  }

  async function addMembers (type) {
    const group = data
    let newArray = []
    $('#btnModalSucess').prop('disabled', true)
    if (type === 'users') {
      if (selectedRows.addUsers.length === 0) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Debe seleccionar al menos 1 usuario!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else {
        if (group.users.length !== 0) {
          newArray = group.users
        }
        for (const selected of selectedRows.addUsers) {
          newArray.push(selected)
        }
        setSelectedRows({ ...selectedRows, addUsers: [], users: [] })
        group.users = newArray

        const response = await putData('groups', group)
        if (response.data.result) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Insercion exitosa!',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error en la insercion!',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        }
      }
    } else if (type === 'devices') {
      if (selectedRows.addDevices.length === 0) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Debe seleccionar al menos 1 equipo!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else {
        if (group?.devices.length !== 0) {
          newArray = group.devices
        }
        for (const selected of selectedRows.addDevices) {
          newArray.push(selected)
        }
        setSelectedRows({ ...selectedRows, addDevices: [], devices: [] })
        group.devices = newArray
        const response = await putData('groups', group)
        if (response.data.result) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Insercion exitosa!',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error en la insercion!',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        }
      }
    } else if (type === 'geofences') {
      if (selectedRows.addGeofences.length === 0) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Debe seleccionar al menos 1 geocerca!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else {
        if (group.geofences.length !== 0) {
          newArray = group.geofences
        }
        for (const selected of selectedRows.addGeofences) {
          newArray.push(selected)
        }
        setSelectedRows({ ...selectedRows, addGeofences: [], geofences: [] })
        group.geofences = newArray

        const response = await putData('groups', group)

        if (response.data.result) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Insercion exitosa!',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error en la insercion!',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            $('#btnModalSucess').prop('disabled', false)
          }, 1500)
        }
      }
    }

    setModalsShow({
      ...modalsShow,
      addMembers: {
        addDevices: false,

        addUsers: false,
        addGeofences: false
      }
    })
    await membersNotGroup()
  }

  async function deleteMembers (type) {
    $('#btnModalSucess').prop('disabled', true)
    let titleMessage = ''
    if (type === 'users' && selectedRows.users.length === 0) {
      titleMessage = 'Debes seleccionar los usuarios que desea eliminar!'
    } else if (type === 'devices' && selectedRows.devices.length === 0) {
      titleMessage = 'Debes seleccionar los equipos que desea eliminar!'
    } else if (type === 'geofences' && selectedRows.geofences.length === 0) {
      titleMessage = 'Debes seleccionar las geocercas que desea eliminar!'
    }

    if (titleMessage !== '') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: titleMessage,

        showConfirmButton: false,
        timer: 1500
      })
      setTimeout(() => {
        $('#btnModalSucess').prop('disabled', false)
      }, 1500)
    } else {
      const filters = filtered(type, data)
      setSelectedRows({
        ...selectedRows,
        users: [],
        addUsers: [],
        devices: [],
        addDevices: [],
        geofences: [],
        addGeofences: []
      })
      const response = await putData('groups', filters)

      if (response.data.result) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Eliminacion exitosa!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Eliminacion fallida!',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          $('#btnModalSucess').prop('disabled', false)
        }, 1500)
      }

      await membersNotGroup()
    }
  }

  function showAddMembers (type) {
    setModalsShow({
      ...modalsShow,
      addMembers: {
        ...modalsShow.addMembers,
        [type]: true
      }
    })
  }

  function handleModal (type, boolean) {
    if (type === 'desbloquear' || type === 'bloquear') {
      setAction(type)
      setModalsShow({ ...modalsShow, locked: boolean })
    } else {
      setModalsShow({ ...modalsShow, [type]: boolean })
    }
  }

  const handleChange = (data, type) => {
    setSelectedRows({
      ...selectedRows,
      [type]: data
    })
  }

  function closeModal (type) {
    setModalsShow({
      ...modalsShow,
      addMembers: {
        [type]: false
      }
    })
    setSelectedRows({
      users: [],
      addUsers: [],
      devices: [],

      addDevices: [],
      geofences: [],
      addGeofences: []
    })
  }

  if (data === null || preferences === null) {
    return (
      <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
        <div className={`content  ${stylesIndex.mainContent}`}>
          <Loading color="primary" />
        </div>
      </div>
    )
  } else {
    return (
      <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
        <div className={`content  ${stylesIndex.mainContent}`}>
          {role?.dashboardGroupPage.buttonSpeedDial && <SpeedDialMenu
            onHide={handleModal}
            data={data}
            // deleted={deleted}
            idGroup={id}
            type="groups"
            dashboardGroupPage={role?.dashboardGroupPage}
          />}
          <Toast ref={toast} />
          <Header
            title={data.name}
            edit={true}
            data={data}
            type="group"
            reload={getDataGroup}
          />
          <div>
            <h3 style={{ marginTop: '2rem' }}>Usuarios</h3>
            <ButtonsAndTables
              remove={() => deleteMembers('users')}
              add={() => showAddMembers('addUsers')}
              addBtn={role?.dashboardGroupPage.addUsers}
              removeBtn={role?.dashboardGroupPage.deleteUsers}
              exportPdf={role?.dashboardGroupPage.exportPdfUsers}
              exportCsv={role?.dashboardGroupPage.exportCsvUsers}
              rowSelecteds={role?.dashboardGroupPage.selectRowsDataTableUsers}
              table="users"
              type="dataTableDemo"
              rowStates={false}
              actionDashboard={false}
              actionDelete={false}
              editUsers={false}
              search={['name', 'user', 'last', 'phone', 'email']}
              onSelecteds={handleChange}
              tableData={data.users}
              columns={[
                {
                  field: 'user',
                  header: 'Usuario'
                },
                {
                  field: 'name',
                  header: 'Nombre'
                },
                {
                  field: 'last',
                  header: 'Apellido'
                },
                {
                  field: 'phone',
                  header: 'Telefono'
                },
                {
                  field: 'email',
                  header: 'Email'
                }
              ]}
            />

            <h3 style={{ marginTop: '2rem' }}>Equipos</h3>
            <ButtonsAndTables
              remove={() => deleteMembers('devices')}
              add={() => showAddMembers('devices')}
              addBtn={role?.dashboardGroupPage.addDevices}
              removeBtn={role?.dashboardGroupPage.deleteDevices}
              exportPdf={role?.dashboardGroupPage.exportPdfDevices}
              exportCsv={role?.dashboardGroupPage.exportCsvDevices}
              rowSelecteds={role?.dashboardGroupPage.selectRowsDataTableDevices}
              table="devices"
              search={columnsRole.filter((column) => column.field !== 'type' && column.field !== 'status_lock').map((colum) => colum.field)}
              rowStates={true}
              actionDashboard={false}
              actionDelete={false}
              editUsers={false}
              onSelecteds={handleChange}
              tableData={data.devices}
              type="dataTableDemo"
              columns={columnsRole.filter((column) => column.field !== 'type' && column.field !== 'status_lock')}
              columnStatus ={columnsRole}
            />

            <h3 style={{ marginTop: '2rem' }}>Geocercas</h3>
            <ButtonsAndTables
              remove={() => deleteMembers('geofences')}
              add={() => showAddMembers('addGeofences')}
              addBtn={role?.dashboardGroupPage.addGeofences}
              removeBtn={role?.dashboardGroupPage.deleteGeofences}
              exportPdf={role?.dashboardGroupPage.exportPdfGeofences}
              exportCsv={role?.dashboardGroupPage.exportCsvGeofences}
              rowSelecteds={role?.dashboardGroupPage.selectRowsDataTableGeofences}
              table="geofences"
              type="dataTableDemo"
              rowStates={false}
              actionDashboard={false}
              actionDelete={false}
              editUsers={false}
              search={['name', 'description', 'type', 'place', 'date_order']}
              onSelecteds={handleChange}
              tableData={data.geofences}
              columns={[
                {
                  field: 'name',
                  header: 'Nombre'
                },
                {
                  field: 'description',
                  header: 'Descripción'
                },
                {
                  field: 'type',
                  header: 'Tipo'
                },
                {
                  field: 'place',
                  header: 'Ubicación'
                },
                {
                  field: 'date_order',
                  header: 'Fecha de creación'

                }
              ]}
            />
          </div>

          {/* MODAL PARA AGREGAR USUARIOS */}
          <ModalGeneric
            show={modalsShow.addMembers.addUsers}
            onHide={() => closeModal('users')}
            size="xl"
            action="addUsers"
            id="addUsers"
            onClose={() => closeModal('users')}
            title="Agregar Usuarios"
            btnError="Cerrar"
            btnSuccess="Agregar"
            actionSuccess={() => addMembers('users')}
          >
            <DataTableDemo
              data={notGroup.users}
              table="addUsers"
              actionDashboard={false}
              actionDelete={false}
              searchs={['name', 'user', 'last', 'phone', 'email']}
              rowStates={false}
              editUsers={false}
              onSelecteds={handleChange}
              columns={[
                {
                  field: 'user',
                  header: 'Usuario'
                },
                {
                  field: 'name',
                  header: 'Nombre'
                },
                {
                  field: 'last',
                  header: 'Apellido'
                },
                {
                  field: 'phone',
                  header: 'Telefono'
                },
                {
                  field: 'email',
                  header: 'Email'
                }
              ]}
            />
          </ModalGeneric>

          {/* MODAL PARA AGREGAR EQUIPOS */}
          <ModalGeneric
            show={modalsShow.addMembers.devices}
            onHide={() => closeModal('devices')}
            action="addDevices"
            id="addDevices"
            size="xl"
            onClose={() => closeModal('devices')}
            title="Agregar Equipos"
            btnError="Cerrar"
            btnSuccess="Agregar"
            actionSuccess={() => addMembers('devices')}
          >
            <DataTableLazyDevices
              table="addDevices"
              onSelecteds={handleChange}
              selectedRows={selectedRows.addDevices}
              groupId={id}
              exportsPdf={role?.devicesPage.exportPdfInDevices}
            exportsCsv={role?.devicesPage.exportCsvInDevices}
            rowSelecteds={role?.devicesPage.selectRowsDataTableInDevices}
            clearSelection={role?.devicesPage.selectRowsDataTableInDevices}
            actionDashboard={false}
            actionDelete={false}
            columnsRole={columnsRole}
            />
          </ModalGeneric>

          {/* MODAL PARA AGREGAR GEOCERCAS */}
          <ModalGeneric
            show={modalsShow.addMembers.addGeofences}
            onHide={() => closeModal('geofences')}
            size="xl"
            action="addGeofences"
            id="addGeofences"
            onClose={() => closeModal('geofences')}
            title="Agregar Geocercas"
            btnError="Cerrar"
            btnSuccess="Agregar"
            actionSuccess={() => addMembers('geofences')}
          >
            <DataTableDemo
              data={notGroup.geofences}
              table="addGeofences"
              actionDashboard={false}
              actionDelete={false}
              searchs={['name', 'description', 'type', 'place', 'date_order']}
              rowStates={false}
              editUsers={false}
              onSelecteds={handleChange}
              columns={[
                {
                  field: 'name',
                  header: 'Nombre'
                },
                {
                  field: 'description',
                  header: 'Descripción'
                },
                {
                  field: 'type',
                  header: 'Tipo'
                },
                {
                  field: 'place',
                  header: 'Ubicación'
                },
                {
                  field: 'date_order',
                  header: 'Fecha de Creación'
                }
              ]}
            />
          </ModalGeneric>

          {/* MODAL CONFIGURACIONES DE REPORTES */}
          <ConfigReportGroups
            show={modalsShow.reports}
            onHide={() => handleModal('reports', false)}
          />

          {/* MODAL ENVIAR MENSAJE */}
          <SendMessages
            type="dashboardGroup"
            show={modalsShow.message}
            onHide={() => handleModal('message', false)}
            group={[parseInt(id)]}
            devices={idsDevices}
            title="Enviar Mensaje al Grupo"
            component="dashboardGroups"
          />

          {/* MODAL BLOQUEAR */}
          <LockedDevices
            show={modalsShow.locked}
            onHide={() => handleModal('locked', false)}
            action={action !== null ? action : null}
            preferences={
              preferences && preferences !== null
                ? preferences.defaultThief
                : null
            }
            group={[parseInt(id)]}
            devices={idsDevices}
            title={`Esta seguro que quiere ${action} los dispositivos del grupo?`}
          />

          {/* MODAL CAMBIO DE FONDO DE PANTALLA */}
          <ModalBackgroundChange
            show={modalsShow.backgroundChange}
            onHide={() => handleModal('backgroundChange', false)}
            title="Cambiar Fondo de Pantalla"
            btnError="Cerrar"
            btnSuccess="Enviar"
            // group={[parseInt(id)]}
            // devices={idsDevices}
            type="groups"
          />

          {/* MODAL DE DESCARGA DE CONTENIDO */}
          <ModalContentDownload
            show={modalsShow.contentDownload}
            onHide={() => handleModal('contentDownload', false)}
            title="Descargar Contenido"
            btnError="Cerrar"
            btnSuccess="Enviar"
            type="groups"
          />

          {/* MODAL DE PREFERENCIAS DE TIEMPO DE USO */}
          <ModalUseTimePreferences
            show={modalsShow.useTimePreferences}
            onHide={() => handleModal('useTimePreferences', false)}
            title="Preferencias de Tiempo de Uso"
            btnError="Cerrar"
            btnSuccess="Enviar"
            group={[parseInt(id)]}
            useTimeId={preferences?.use_time?.id}
          />

          {/* MODAL DE ELIMINAR PREFERENCIAS DEL GRUPO */}
          <ModalDeleteGroupPreference
            show={modalsShow.deleteGroupPreference}
            onHide={() => handleModal('deleteGroupPreference', false)}
            title="¿Esta seguro que quiere eliminar la preferencia del grupo?"
            btnError="Cerrar"
            btnSuccess="Enviar"
            group={[parseInt(id)]}
          />

          {/* MODAL DE INSTALACIÓN DE APLICACIONES */}
          <ModalInstallApplications
            show={modalsShow.installApplications}
            onHide={() => handleModal('installApplications', false)}
            title="Instalar Aplicaciones"
            btnError="Cerrar"
            btnSuccess="Instalar"
            type="groups"
          />

          {/* MODAL DE DESINSTALACIÓN DE APLICACIONES */}
          <ModalUninstallApplications
            show={modalsShow.uninstallApplications}
            onHide={() => handleModal('uninstallApplications', false)}
            title="Desinstalar Aplicaciones"
            btnError="Cerrar"
            btnSuccess="Desinstalar"
            type="groups"
          />
        </div>
      </div>
    )
  }
}
