import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import '../../css/generals/paper.css'
import stylesCheck from '../../css/generals/Checkbox.module.css'
import stylesModal from '../../css/modals/Modal.module.css'
import { getData, postData } from '../../actions/servicesHttp'
import { popupSimple } from '../generals/popups'
import PropTypes from 'prop-types'
import Loading from '../generals/loading'
import DataTableDemo from '../generals/datatables/datatable'

export default function ModalUninstallApplications ({ show, onHide, title, btnError, btnSuccess, type }) {
  const { id } = useParams()

  // ? urls de la API
  const [applicationsUrl] = useState('group/applications')

  //* estados de componentes
  const [applications, setApplications] = useState()
  const [system, setSystem] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  //* llamador de datos de API
  const getRequest = async (url, set) => {
    const array = ['No Permitidas', 'Sistema', 'Educativas', 'No Educativas', 'Productividad', 'Internet', 'Ocio', 'Otras']
    const response = await getData(url)
    // console.log(response, url)
    if (response.status === 200) {
      if (response.data) {
        if (response.data.length === 0) set([])
        else {
          // console.log(response)
          response.data?.apps.forEach((app) => {
            if (app.groupApp !== null && app.groupApp >= 0 && app.groupApp <= 7) {
              app.category = array[app.groupApp]
            } else {
              app.category = 'Sin asignar'
            }
          })

          const data = response.data
          let apps
          if (system) {
            apps = response.data?.apps.filter((app) => app.system === true)
          } else {
            apps = response.data?.apps.filter((app) => app.system !== true)
          }
          data.apps = apps
          set(data)
        }
      }
    } else {
      set({ error: true, code: response.code })
    }
  }

  useEffect(() => {
    if (show) getRequest(applicationsUrl, setApplications)
  }, [applicationsUrl, show, system])

  function onChange (e) {
    setSystem(e.target.checked)
  };

  function closeModal () {
    onHide()
    setSelectedRows([])
  };

  const handleChange = (data) => {
    setSelectedRows(data)
  }

  async function uninstallApps (data) {
    const arrayApps = []

    if (data.length !== 0) {
      data.forEach(element => {
        const json = {
          action: 'uninstall',
          data: {
            uninstall: element.uninstall
          },
          date: new Date(Date.now()),
          devicesId: [],
          groupsId: [parseInt(id)]
        }
        arrayApps.push(json)
      })

      const response = await postData('actions', arrayApps)

      if (response.data[0].result === true) {
        popupSimple('success', 'Solicitud enviada con éxito!')
      } else {
        popupSimple('error', 'Error. No se pudo realizar la acción!')
      }
      closeModal()
    } else {
      popupSimple('info', 'Debe seleccionar las aplicaciones a desinstalar!')
    }
  };

  return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                key={'uninstallApplications'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className={stylesModal.check_modal_uninstall_apps_groups}>
                            <label className={stylesCheck.sliderLabel}>Sistema</label>
                            <label className={stylesCheck.switch}>
                                <input
                                    value={system}
                                    checked={system}
                                    onChange={onChange}
                                    type="checkbox"
                                    className="focus"
                                />
                                <span
                                    className={`${stylesCheck.slider} ${stylesCheck.round} ${stylesCheck.inputsLabels}`}
                                />
                            </label>
                        </div>
                        {applications !== undefined
                          ? (<DataTableDemo
                            data={applications.apps}
                            actionDashboard={false}
                            actionDelete={false}
                            editUsers={false}
                            rowStates={false}
                            rowSelecteds={true}
                            exportCsv={false}
                            exportsPdf={false}
                            searchs={['app']}
                            onSelecteds={handleChange}
                            columns={[
                              {
                                field: 'app',
                                header: 'Nombre'
                              },
                              {
                                field: 'version',
                                header: 'Version'
                              },
                              {
                                field: 'category',
                                header: 'Categoria'
                              }
                            ]}
                        />)
                          : (<Loading color="primary" />)}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => closeModal()} variant="danger">
                        {btnError}
                    </Button>
                    <Button onClick={() => uninstallApps(selectedRows)} variant="primary" id="btnModalSucess" >
                        {btnSuccess}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  )
}

ModalUninstallApplications.propTypes = {
  onHide: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  btnError: PropTypes.string,
  btnSuccess: PropTypes.string
}
