import $ from 'jquery'
import React from 'react'
import { SpeedDial } from 'primereact/speeddial'
import { Tooltip } from 'primereact/tooltip'
import {
  jsonActionsSystemDevices,
  jsonActionsContent,
  jsonActionsSecurityDevices,
  jsonGroupPreferences
} from '../../views/Devices/Dashboard/Assets/jsons'
import {
  jsonActionsSystemGroups,
  jsonActionsSecurityGroups,
  jsonApplicationsGroups
} from '../../views/Groups/Dashboard/Assets/jsons'
import { Button } from 'primereact/button'
import { postData } from '../../actions/servicesHttp'
import Swal from 'sweetalert2'
import { popupSimple } from '../../components/generals/popups'
import styles from '../../css/Dashboards/SpeedDialMenu.module.css'
import PropTypes from 'prop-types'

export default function SpeedDialMenu ({ data, deleted, id, identity, onHide, dashboardDevicePage, type, idGroup, dashboardGroupPage, socketSendActions, realTime, setDevice, setFlagReload }) {
  async function rebootShutdownRestart (action, text, type, identity) {
    const titleSwal = `${text}`
    const textSwal =
      type === 'devices'
        ? `¿Está seguro que desea ${text.toLowerCase()} el dispositivo?`
        : `¿Está seguro que desea ${text.toLowerCase()} los dispositivos?`
    const powerState = action

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons
      .fire({
        title: titleSwal,
        icon: 'warning',
        text: textSwal,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const json = {
            action: 'power_config',
            data: {
              powerState
            },
            date: new Date(Date.now()),
            devicesId: type === 'devices' ? [parseInt(id)] : [],
            groupsId: type === 'groups' ? [parseInt(idGroup)] : []
          }

          if (realTime) {
            socketSendActions([json])
            popupSimple('success', 'Acción realizada con éxito!')
          } else {
            const { data } = await postData('actions', [json])

            if (type === 'devices') {
              if (
                data[0].result === true &&
                Array.isArray(data[0].sendings) &&
                data[0].sendings.length === 0
              ) {
                popupSimple('success', 'Solicitud enviada con éxito!')
              } else if (
                data[0].result === true &&
                Array.isArray(data[0].sendings) &&
                data[0].sendings.length > 0 &&
                data[0].sendings.includes(identity)
              ) {
                popupSimple('success', 'Acción realizada con éxito!')
              } else {
                popupSimple('error', 'Error. No se pudo realizar la acción!')
              }
            } else {
              if (data[0].result === true) {
                popupSimple('success', 'Solicitud enviada con éxito!')
              } else {
                popupSimple('error', 'Error. No se pudo realizar la acción!')
              }
            }
          }
        }
      })
  }

  const itemsSystem =
    type === 'devices'
      ? jsonActionsSystemDevices(
        rebootShutdownRestart,
        id,
        onHide,
        identity,
        data.so,
        dashboardDevicePage
      )
      : jsonActionsSystemGroups(rebootShutdownRestart, idGroup, dashboardGroupPage)

  const itemsContent = jsonActionsContent(onHide, data.so, type === 'devices' ? dashboardDevicePage : dashboardGroupPage)

  const itemsSecurity =
    data !== null
      ? type === 'devices'
        ? jsonActionsSecurityDevices(onHide, data, deleted, data.so, dashboardDevicePage)
        : jsonActionsSecurityGroups(onHide, data, deleted, dashboardGroupPage)
      : []

  const itemsPreferences = type === 'groups' ? jsonGroupPreferences(onHide, dashboardGroupPage) : []

  const itemsApplications = type === 'groups' ? jsonApplicationsGroups(idGroup, dashboardGroupPage, onHide) : []

  function openMenuSpeedDial () {
    $('#contentMenuSpeedDial').toggleClass(`${styles.menuZIndex}`)
    $('#i-btnMenuSpeedDial').toggleClass('btnIRight')
    $('#i-btnMenuSpeedDial').toggleClass('btnILeft')
    $('.p-speeddial-button').toggleClass(`${styles.openBtn}`)
    $('#btnDial4').toggleClass(`${styles.openBtn}`)
    $('#btnDial5').toggleClass(`${styles.openBtn}`)
  }

  return (
    <>
      <Button
        severity="secondary"
        className={styles.btnMenuSpeedDial}
        onClick={() => openMenuSpeedDial()}
        ref={(el) => {
          if (el) {
            el.style.setProperty('background-color', 'var(--gray-light-color)', 'important')
            el.style.setProperty('border', 'none', 'important')
          }
        }}
      >
        <i className="pi pi-plus btnILeft" id="i-btnMenuSpeedDial"></i>
      </Button>
      <div className={styles.menuSpeedDial} id="contentMenuSpeedDial">
        <Tooltip target=".p-speeddial-action" position="top" />
        {itemsSystem?.length !== 0 && <SpeedDial
          model={itemsSystem}
          direction="left"
          buttonClassName={styles.btnDial1}
          type="linear"
          showIcon="pi pi-power-off"
          hideIcon="pi pi-power-off"
          hideOnClickOutside={true}
        />}
        {itemsContent?.length !== 0 && <SpeedDial
          model={itemsContent}
          direction="left"
          buttonClassName={styles.btnDial2}
          type="linear"
          showIcon="pi pi-send"
          hideIcon="pi pi-send"
          hideOnClickOutside={true}
          style={{ top: '4rem' }}
        />}
        {itemsSecurity?.length !== 0 &&
        <SpeedDial
          model={itemsSecurity}
          direction="left"
          buttonClassName={styles.btnDial3}
          type="linear"
          showIcon="pi pi-lock"
          hideIcon="pi pi-lock"
          hideOnClickOutside={true}
          style={{ top: '8rem' }}
        />}
        {itemsPreferences?.length !== 0 &&
          <SpeedDial
            model={itemsPreferences}
            direction="left"
            buttonClassName={styles.btnDial4}
            type="linear"
            showIcon="pi pi-cog"
            hideIcon="pi pi-cog"
            hideOnClickOutside={true}
            style={{ top: '12rem' }}
          />}
        {itemsApplications?.length !== 0 &&
          <SpeedDial
            model={itemsApplications}
            direction="left"
            buttonClassName={styles.btnDial5}
            type="linear"
            showIcon="pi pi-th-large"
            hideIcon="pi pi-th-large"
            hideOnClickOutside={true}
            style={{ top: '16rem' }}
          />}
        {type === 'devices' && dashboardDevicePage?.actionApplications &&
          <Button
            id="btnDial4"
            icon="pi pi-th-large"
            severity="secondary"
            tooltip="Aplicaciones"
            tooltipOptions={{ position: 'left' }}
            style={{ top: '12rem' }}
            className={styles.btnApps}
            onClick={() => onHide('apps', true)}
            ref={(el) => {
              if (el) {
                el.style.setProperty('background-color', 'var(--gray-light-color)', 'important')
                el.style.setProperty('border', 'none', 'important')
              }
            }}
          />}
          {type === 'devices' && data?.id_rxart && dashboardDevicePage?.actionRxartSecure &&
          <Button
            id="btnDial5"
            icon="pi pi-shield"
            severity="secondary"
            tooltip="Rxart Secure"
            tooltipOptions={{ position: 'left' }}
            // style={{ top: '0.5rem' }}
            className={styles.btnRxart}
            onClick={() => onHide('RSDevice', true)}
            ref={(el) => {
              if (el) {
                el.style.setProperty('background-color', 'var(--gray-light-color)', 'important')
                el.style.setProperty('border', 'none', 'important')
              }
            }}
          />}
      </div>
    </>
  )
}

SpeedDialMenu.propTypes = {
  type: PropTypes.string,
  onHide: PropTypes.func,
  deleted: PropTypes.func,
  idGroup: PropTypes.number,
  id: PropTypes.string,
  identity: PropTypes.string,
  data: PropTypes.object,
  dashboardDevicePage: PropTypes.object,
  dashboardGroupPage: PropTypes.object,
  socketSendActions: PropTypes.func,
  realTime: PropTypes.bool,
  setDevice: PropTypes.func,
  setFlagReload: PropTypes.func
}
